import(/* webpackMode: "eager" */ "/Users/masayoshi/Documents/macspace.id/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/masayoshi/Documents/macspace.id/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/masayoshi/Documents/macspace.id/src/app/home.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/masayoshi/Documents/macspace.id/src/components/core/Popup/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/masayoshi/Documents/macspace.id/src/components/core/RunningText/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/masayoshi/Documents/macspace.id/src/components/core/Whatsapp/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/masayoshi/Documents/macspace.id/src/components/layouts/Jumbotron/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/masayoshi/Documents/macspace.id/src/components/layouts/Navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Label"] */ "/Users/masayoshi/Documents/macspace.id/src/components/ui/label.tsx");
;
import(/* webpackMode: "eager" */ "/Users/masayoshi/Documents/macspace.id/src/components/ui/select.tsx");
